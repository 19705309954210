import ApiClient from '../api/api';

class ProjectService {


    constructor(){
        this.inited = false;
        this.projectsDirectory = [];
        this.apiclient = new ApiClient();
        this.apiclient.do_get_project_directory().then(x=>{
            this.projectsDirectory = x;
            console.log(this.projectsDirectory)
            let ps = this;
            setTimeout(()=>{ps.inited = true});
        })
    }

    async listProjects(){
        while (!this.inited){
            await new Promise(r => setTimeout(r, 100));
        }
        return this.projectsDirectory.map(x=>x.projectName);
        
    }
    async listUsersPerProject(projectName){
        while (!this.inited){
            await new Promise(r => setTimeout(r, 100));
        }
        return this.projectsDirectory.find(x=>x.projectName === projectName)?.participants;
    }
    async listProjectsPerUser(userEmail, isAdmin){
        while (!this.inited){
            console.log("WAITING")
            await new Promise(r => setTimeout(r, 100));
        }
        if (isAdmin) return this.projectsDirectory.map(x=>x.projectName);
        return this.projectsDirectory.filter(x=>x.participants.includes(userEmail)).map(x=>x.projectName);
    }
}

export default ProjectService;