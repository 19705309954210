import React from "react";
import ApiClient from "../../services/api/api";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export default function JobList({project}) {

    const apiClient = new ApiClient();
    const [jobs, setJobs] = React.useState([]);
    React.useEffect(() => {
        apiClient.do_list_jobs({project}).then(setJobs)
    }, [project]);
    const saveContent = (url, fileName) => {
        console.log(url);
        console.log(fileName);
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        console.log(link);
        link.click();
        setTimeout(() => {
            document.body.removeChild(link);
        },2000);
      }
    const onDownload = async ({jobId, fileName})=>{
        const psurl = await apiClient.do_prepare_download({jobId});
        const {url} = psurl;

        saveContent(url, fileName);
    };


    const columns = [
        // { field: 'jobId', headerName: 'Job Id', flex: 1.5 },
        { field: 'fileName', headerName: 'File Name', flex: 1 },
        { field: 'jobState', headerName: 'Stato', flex: .5 },
        // { field: 'step1State', headerName: 'Step 1', flex: .5 },
        // { field: 'step2State', headerName: 'Step 2', flex: .5 },
        // { field: 'step3State', headerName: 'Step 3', flex: .5 },
        { field: 'creator', headerName: 'Creator', flex: 1 },
        { field: 'creationDatetime', headerName: 'Creation Datetime', flex: .5 },
        // { field: 'expiration', headerName: 'Scade', flex: .5 },
        { field: 'selectedProject', headerName: 'Progetto', flex: .5 },
        // campo con pulsante per scaricare il file
        { field: 'download', headerName: 'Download', flex: .2, type: 'actions', getActions: (params)=>params.row.jobState === "COMPLETED" ? [
            <GridActionsCellItem icon={<DownloadIcon />} onClick={()=>onDownload({jobId: params.id, fileName: params.row.fileName})} label="Download"/>
        ] : [] },
    ];

    return <>
        <div style={{ height: '83vh', width: '100%' }}>
            <Typography variant="h6" gutterBottom>Lavori richiesti { project ? "nel progetto "+project : "dall'utente"}</Typography>
            <DataGrid rows={jobs} columns={columns} pageSize={5} getRowId={r => r.jobId} />
        </div>
    </>
}