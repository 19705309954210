class UserService {

    constructor(){
        console.log("US constr");
    }
    
    async listUserEmails() {
        console.log("US lue");
        return ['l.loschi@reply.it','a.brudaglio@reply.it']
    }
}

export default UserService;