import React from 'react';
import { Box, Typography, Button, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { OverallCurrentPage } from '../context';
import SectionCard from '../components/sectionCard/sectionCard';
import { useNavigate } from "react-router-dom";

const HomePage = ({
}) => {

    const navigate = useNavigate();

    const { setCurrentPage } = React.useContext(OverallCurrentPage)

    return (
        <Box>
            <Card
                elevation={10}
                sx={{
                    bgcolor: 'white',
                    color: 'black',
                    my: 4,
                    py: 8,
                    px:6,
                    mx: 6,
                    textAlign: 'center',
                    borderRadius: '15px',
                    //   boxShadow: '5px 5px 0px 0px rgba(0,0,200,0.3)',
                }}
            >
                <Typography variant="h2" component="h1" gutterBottom >
                    TechDoc Genie
                </Typography>
                <Typography variant="h5" gutterBottom fontFamily={'Calibri'} >
                    Questa applicazione permette di creare automaticamente documenti di Analisi Tecnica a partire da un file OutDoc.<br />

                    L'applicazione è in beta preview e non ha ancora tutte le funzionalità. Richieste e suggerimenti sono fortemente graditi.
                </Typography>
                
                <Button variant="contained" color="secondary" sx={{ mt: 4}} onClick={() => navigate("/newJob")}>
                    Quick start
                </Button>
            </Card>

            <Box sx={{ py: 6, px: 6 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Come contribuire?" imagePath="/imgs/sharing_ideas.jpg">
                        Se hai segnalazioni o richieste apri una <a href="https://github.com/kireply/TechDocGenie/issues" target='_blank'>issue</a> sul repository del progetto.
                        </SectionCard>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Generazione Analisi Tecnica" imagePath="/imgs/tech_analysis.jpg">
                            <Typography variant="body1">
                                Puoi creare <a href="#" onClick={() => navigate('/newJob')}>qui</a> una nuova richiesta di generazione di analisi tecnica.<br />
                            </Typography>
                            <Typography variant="body1">
                                Oppure puoi verificare i lavori che hai già richiesto <a href="#" onClick={() => navigate('/jobList')}>qui</a>.
                            </Typography>
                        </SectionCard>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ justifyContent: 'center', display:'flex'}}>
                        <SectionCard title="Progetti" imagePath="/imgs/projects.jpg">
                        <Typography variant="body1">
                                Da qui potrai gestire i progetti. <br />
                            </Typography>
                            <Typography variant="body1">
                                Un progetto raccoglie materiale e persone e rende più facile la collaborazione.<br />
                            </Typography>
                            <Typography color="error" variant="body1">
                                Funzionalità non ancora disponibile.
                            </Typography>
                        </SectionCard>
                    </Grid>
                </Grid>
                
            </Box>
        </Box>
    );
};

export default HomePage;

