
var amplifyConfiguration
if (!process.env?.NODE_ENV || process.env?.NODE_ENV === 'development') {
    amplifyConfiguration = {
        "Auth": {
            "Cognito": {
                "region": "eu-south-1",
                "userPoolId": "eu-south-1_XOdA58D6i",
                "userPoolClientId": "1qnlfhstvcg54ichempd323c0q",
                "identityPoolId": "eu-south-1:aec6eb13-a217-4e2d-9935-594f42159078",
                "signUpVerificationMethod": "code",
                "loginWith": {
                    "oauth": {
                        "domain": "kiup1.auth.eu-south-1.amazoncognito.com",
                        "scopes": [
                            "phone",
                            "email",
                            "profile",
                            "openid",
                            "aws.cognito.signin.user.admin"
                        ],
                        "redirectSignIn": ["http://localhost:54114"],
                        "redirectSignOut": ["http://localhost:54114"],
                        "responseType": "code"
                    }
                }
            }
        }
    }
} else {
    amplifyConfiguration = {
        "Auth": {
            "Cognito": {
                "region": "eu-south-1",
                "userPoolId": "eu-south-1_XOdA58D6i",
                "userPoolClientId": "1qnlfhstvcg54ichempd323c0q",
                "identityPoolId": "eu-south-1:aec6eb13-a217-4e2d-9935-594f42159078",
                "signUpVerificationMethod": "code",
                "loginWith": {
                    "oauth": {
                        "domain": "kiup1.auth.eu-south-1.amazoncognito.com",
                        "scopes": [
                            "phone",
                            "email",
                            "profile",
                            "openid",
                            "aws.cognito.signin.user.admin"
                        ],
                        "redirectSignIn": ["https://techdoc.platform.kicode.it"],
                        "redirectSignOut": ["https://techdoc.platform.kicode.it"],
                        "responseType": "code"
                    }
                }
            }
        }
    }
}

export default amplifyConfiguration;