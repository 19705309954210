import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import DrawerAppBar from './AppBar';
import Login from './Login';
import JobInputForm from './components/jobInputForm/jobInputForm';
import ListJobs from './pages/listJobs';
import HomePage from './pages/home';
import { OverallCurrentPage, User } from './context';
import useLocalStorage from 'react-use-localstorage';
import NewJobPage from './pages/newJob';
import useSessionStorageState from 'use-session-storage-state'
import * as ReactDOM from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Routes, Route,  
    BrowserRouter as Router
} from "react-router-dom";

export default function App() {
    const [user, setUser] = useState(null);
    const [fullUser, setFullUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [customState, setCustomState] = useState(null);
    const [currentPage, setCurrentPage] = useSessionStorageState('currentPage', { defaultValue: 'home' });


    const routes = [
        {
            path: "/",
            element: <HomePage />,
        },
        {
            path: "/home",
            element: <HomePage />,
        },

        {
            path: "/welcome",
            element: <>
                <h1>This is TechDoc Genie</h1>
                <div>Welcome </div>
                {/* <div><pre>{JSON.stringify(user, null, 2)}</pre></div>
                <div><pre>{JSON.stringify(fullUser, null, 2)}</pre></div> */}
            </>
        },
        {
            path: "/jobInput",
            element: <JobInputForm />
        },
        {
            path: "/newJob",
            element: <NewJobPage />
        },
        {
            path: "/jobList",
            element: <ListJobs />
        },

    ];

    useEffect(() => {
        const unsubscribe = Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case 'signInWithRedirect':
                    getUser();
                    break;
                case 'signInWithRedirect_failure':
                    setError('An error has occurred during the Oauth flow.');
                    break;
                case 'customOAuthState':
                    setCustomState(payload.data);
                    break;
            }
        });

        getUser();

        return unsubscribe;
    }, []);

    useEffect(() => {
        (async () => {
            if (!!user) {
                const fulluser = await fetchAuthSession();
                console.log(fullUser);
                if (fulluser.tokens.idToken.payload['cognito:groups'].includes('iamidc_techdoc_genie_admins')) { //DA VEDERE
                    console.log('is admin');
                    setIsAdmin(true);
                }
                setFullUser(fulluser);
            }
        })();
    }, [user]);

    const getUser = async () => {
        try {
            const currentUser = await getCurrentUser();
            setUser(currentUser);
        } catch (error) {
            console.error(error);
            console.log('Not signed in');
        }
    };

    return (
        <div className="App">
            <Router>
                <OverallCurrentPage.Provider value={{ currentPage, setCurrentPage }}>
                    {!!fullUser ? (<User.Provider value={{ fullUser, isAdmin }}>
                        <DrawerAppBar user={fullUser?.tokens?.idToken?.payload?.email || " "} signOut={signOut} handleMenuClick={(v) => setCurrentPage(v)}>
                            {/* <RouterProvider router={router} /> */}
                            <Routes>
                                {routes.map((route) => <Route path={route.path} element={route.element}/>)}
                            </Routes>
                            {/* 
                        {user?.username && (<>
                            {currentPage === 'home' && (<>
                                <HomePage />
                            </>)}
                            {currentPage === 'welcome' && (<>
                                <h1>This is TechDoc Genie</h1>
                                <div>Welcome {user.username}</div>
                                <div><pre>{JSON.stringify(user, null, 2)}</pre></div>
                                <div><pre>{JSON.stringify(fullUser, null, 2)}</pre></div>
                            </>)}
                            {currentPage === 'jobInput' && <>
                                <JobInputForm />
                            </>}
                            {currentPage === 'newJob' && <>
                                <NewJobPage />
                            </>}
                            {currentPage === 'jobList' && <>
                                <ListJobs />
                            </>}
                        </>)} */}
                        </DrawerAppBar>
                    </User.Provider>) : (
                        <Login onStartLogin={() => signInWithRedirect({ provider: { custom: 'iamidc' } })} />
                    )}
                </OverallCurrentPage.Provider>
            </Router>
        </div>
    );
}