import React from 'react'

export default function FormInput({label, inputComponent, small}) {
  return (
    <div style={{width: '100%'}}>
        <p style={{margin: 0, marginBottom: 2}}>{label}</p>
        {inputComponent}
        
    </div>
  )
}
